<template>
  <div>
    <div v-if="!sended" class="container-raiting mb-5">
      <div class="container-raiting-box px-0">
        <p class="text-center mt-2">
          <b>
            ¿Qué probabilidades hay de que recomiendes Stirpe a un amigo?
          </b>
        </p>
        <div class="d-none d-md-flex flex-wrap my-4">
          <div class="mx-auto">
            <span class="w-100 pe-2">😢</span>
            <button
              v-for="(item, index) in 11"
              :key="index + item"
              :class="[ratingSurvey == index ? 'active' : '']"
              class="btn-rating-order-summary"
              @click="ratingSurvey == null ? (ratingSurvey = index) : ''"
            >
              {{ index }}
            </button>
            <span class="w-100">🤩</span>
          </div>
          <div class="d-flex justify-content-between mx-auto col-12 col-md-10">
            <p>Nada probable</p>
            <p>Muy probable</p>
          </div>
        </div>
      </div>
      <div class="d-flex flex-wrap col-12 d-md-none my-5">
        <div class="w-100 d-flex justify-content-between mb-4">
          <span>😢</span>
          <span>🤩</span>
        </div>
        <div class="w-100">
          <input
            id="range-slider"
            v-model="ratingSurvey"
            type="range"
            list="tickmarks"
            min="0"
            max="10"
          />
          <div id="slide-movil" class="position-relative d-flex">
            <div
              :style="
                ratingSurvey > 0 ? 'background-color: #8DCFD7; width: 3px;' : ''
              "
              :class="ratingSurvey == 0 ? 'd-none' : ''"
              style="
                position: absolute;
                left: 14px;
                top: 7px;
                z-index: 0;
                width: 2px;
                height: 10px;
                background-color: grey;
              "
            />
            <div
              :style="
                ratingSurvey > 1 ? 'background-color: #8DCFD7; width: 3px;' : ''
              "
              :class="ratingSurvey == 1 ? 'd-none' : ''"
              style="
                position: absolute;
                left: calc(10% + 14px);
                top: 7px;
                z-index: 0;
                width: 2px;
                height: 10px;
                background-color: grey;
              "
            />
            <div
              :style="
                ratingSurvey > 2 ? 'background-color: #8DCFD7; width: 3px;' : ''
              "
              :class="ratingSurvey == 2 ? 'd-none' : ''"
              style="
                position: absolute;
                left: calc(19% + 14px);
                top: 7px;
                z-index: 0;
                width: 2px;
                height: 10px;
                background-color: grey;
              "
            />
            <div
              :style="
                ratingSurvey > 3 ? 'background-color: #8DCFD7; width: 3px;' : ''
              "
              :class="ratingSurvey == 3 ? 'd-none' : ''"
              style="
                position: absolute;
                left: calc(29% + 14px);
                top: 7px;
                z-index: 0;
                width: 2px;
                height: 10px;
                background-color: grey;
              "
            />
            <div
              :style="
                ratingSurvey > 4 ? 'background-color: #8DCFD7; width: 3px;' : ''
              "
              :class="ratingSurvey == 4 ? 'd-none' : ''"
              style="
                position: absolute;
                left: calc(38% + 14px);
                top: 7px;
                z-index: 0;
                width: 2px;
                height: 10px;
                background-color: grey;
              "
            />
            <div
              :style="
                ratingSurvey > 5 ? 'background-color: #8DCFD7; width: 3px;' : ''
              "
              :class="ratingSurvey == 5 ? 'd-none' : ''"
              style="
                position: absolute;
                left: 50%;
                top: 7px;
                z-index: 0;
                width: 2px;
                height: 10px;
                background-color: grey;
              "
            />
            <div
              :style="
                ratingSurvey > 6 ? 'background-color: #8DCFD7; width: 3px;' : ''
              "
              :class="ratingSurvey == 6 ? 'd-none' : ''"
              style="
                position: absolute;
                left: calc(60% - 4px);
                top: 7px;
                z-index: 0;
                width: 2px;
                height: 10px;
                background-color: grey;
              "
            />
            <div
              :style="
                ratingSurvey > 7 ? 'background-color: #8DCFD7; width: 3px;' : ''
              "
              :class="ratingSurvey == 7 ? 'd-none' : ''"
              style="
                position: absolute;
                left: calc(69% - 4px);
                top: 7px;
                z-index: 0;
                width: 2px;
                height: 10px;
                background-color: grey;
              "
            />
            <div
              :style="
                ratingSurvey > 8 ? 'background-color: #8DCFD7; width: 3px;' : ''
              "
              :class="ratingSurvey == 8 ? 'd-none' : ''"
              style="
                position: absolute;
                left: calc(79% - 4px);
                top: 7px;
                z-index: 0;
                width: 2px;
                height: 10px;
                background-color: grey;
              "
            />
            <div
              :style="
                ratingSurvey > 9 ? 'background-color: #8DCFD7; width: 3px;' : ''
              "
              :class="ratingSurvey == 9 ? 'd-none' : ''"
              style="
                position: absolute;
                left: calc(88% - 4px);
                top: 7px;
                z-index: 0;
                width: 2px;
                height: 10px;
                background-color: grey;
              "
            />
            <div
              :style="
                ratingSurvey > 10
                  ? 'background-color: #8DCFD7; width: 3px;'
                  : ''
              "
              :class="ratingSurvey == 10 ? 'd-none' : ''"
              style="
                position: absolute;
                left: calc(100% - 14px);
                top: 7px;
                z-index: 0;
                width: 2px;
                height: 10px;
                background-color: grey;
              "
            />
          </div>
          <label
            class="value-range"
            for="slide-movil"
            :style="valueRatingMovil"
            >{{ ratingSurvey }}</label
          >
        </div>
      </div>
      <p v-show="ratingSurvey != null" class="text-center mt-3">
        <b>
          ¿Qué podemos hacer para sorprenderte?
        </b>
      </p>
      <b-form-textarea
        v-show="ratingSurvey != null"
        id="textarea"
        v-model="text"
        rows="4"
        max-rows="8"
        placeholder="Escribe cómo podemos mejorar tu experiencia de compra"
        style="max-width: 600px"
        class="mb-2 col-sm-11 mx-auto"
      ></b-form-textarea>
      <p v-show="ratingSurvey != null" class="text-center">
        <button
          class="bnt-primary mt-3 d-inline-block mb-0 px-5 font-weight-bold btn-summary-comment"
          @click="registerPoll()"
        >
          Enviar
        </button>
      </p>
    </div>
    <div v-else class="container-raiting mb-5">
      <h3 class="mb-0">
        <b>Gracias por tu opinión</b>
      </h3>
    </div>
    <div class="d-flex justify-content-center">
      <button
        class="bnt-primary col-12 col-md-6 col-lg-4 col-xl-3"
        @click="clearDataAndBack()"
      >
        Volver a la tienda
      </button>
    </div>
  </div>
</template>
<script>
import { BFormTextarea } from 'bootstrap-vue'
import { mapActions } from 'vuex'
export default {
  components: { BFormTextarea },
  props: {
    reference: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      text: '',
      ratingSurvey: null,
      sended: false,
      message: '',
      styleRange: [
        'left: 14px;',
        'left: calc(9% + 14px);',
        'left: calc(19% + 13px);',
        'left: calc(30% + 2px);',
        'left: calc(39% + 5px);',
        'left: calc(50% - 4px);',
        'left: calc(60% - 8px);',
        'left: calc(70% - 10px);',
        'left: calc(80% - 15px);',
        'left: calc(90% - 18px);',
        'left: calc(100% - 26px);',
      ],
    }
  },
  computed: {
    valueRatingMovil() {
      return this.styleRange[this.ratingSurvey]
    },
  },
  methods: {
    ...mapActions('cart', ['RegisterPollCheckout']),
    async registerPoll() {
      if (this.ratingSurvey) {
        await this.RegisterPollCheckout({
          pedido_id: this.reference,
          puntaje: this.ratingSurvey,
          mensaje: this.text,
        }).then(() => {
          this.sended = true
        })
      }
    },
    clearDataAndBack() {
      this.$store.state.cart.cart = []
      this.$router.replace({ name: 'Home' })
      localStorage.removeItem('cartTokken')
    },
  },
}
</script>
<style>
#range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  position: relative;
  appearance: none;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background-color: #f8f8f8;
  border: 1px solid #707070;
  cursor: pointer;
  z-index: 3;
}
#range-slider {
  -webkit-appearance: none;
  height: 2px;
  width: 100%;
  padding: 0;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  z-index: 4;
  -webkit-transition: opacity 0.15s ease-in-out;
  transition: opacity 0.15s ease-in-out;
}
.value-range {
  position: relative;
  top: -1.1rem;
  z-index: 1;
  line-height: 1px;
}
.btn-rating-order-summary {
  border: 1px solid rgb(159, 159, 159);
  border-radius: 5px;
  color: black;
  background-color: transparent;
  height: 35px;
  width: 35px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.btn-rating-order-summary.active,
.btn-rating-order-summary:hover {
  background-color: black;
  border-color: black;
  color: white;
  font-family: 'Open Sans bold';
}
.container-raiting {
  max-width: 600px;
  background-color: white;
  border-radius: 5px;
  margin-right: auto;
  margin-left: auto;
  padding: 1rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.container-raiting-box {
  max-width: 600px;
}
.container-raiting-box #summary-survey {
  display: flex;
  align-items: center;
}
.container-raiting-box #summary-survey .btn-secondary {
  background-color: transparent;
  border: 1px solid #001f5d;
  color: #001f5d;
  margin-bottom: 0;
  width: 40px;
  height: 40px;
  margin-right: 0.3rem;
  background-color: #eaf0fc !important;
}
.container-raiting-box #summary-survey span {
  font-size: 26px;
}
.container-raiting-box #summary-survey .btn-secondary.active,
.container-raiting-box #summary-survey .btn-secondary:hover {
  background-color: #001f5d !important;
  border-color: #001f5d;
  color: white;
  font-weight: bold;
}
</style>
